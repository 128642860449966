import React from "react";
import { Container } from 'react-bootstrap';
import './SearchBar.css';
import logoImage from './YNCN_logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons';

function SearchBar({
  dropdownDisplay,
  setDropdown,
  onSearchChange,
  searchText,
  setSearchText,
  onClearText
}) {
  const searchTextOnChange = (event) => {
    const query = event.target.value;
    setSearchText(query);
    onSearchChange(query);
  };

  /**
   * Handle interactive dropdown display functionality whenever the 
   * user focuses on the search bar component.
   * 
   * @param {import('react').SyntheticEvent} event Synthetic event.
   * @returns {void}
   */
  const focusHandler = (event) => {
    const query = event.target.value;
    if (query !== "") {
      setDropdown(true);
    }
  };

  /**
   * Handle dropdown display by hiding the dropdown whenever the
   * user no longer focuses on the search bar component.
   * 
   * @returns {void}
   */
  const blurHandler = () => {
    setDropdown(false);
  };

  /**
   * Clear the search text dynamically.
   * 
   * @returns {void}
   */
  const clearSearchText = () => {
    setSearchText('');
    onSearchChange('');
    onClearText();
  };

  return (
    <div className='search-bar'>
      <Container className='searchbar-container'>
        <div className="logo-container">
          <a href="https://www.yourenext.ca/">
            <center><img src={logoImage} alt="Logo" className="logo" /></center>
          </a>
        </div>
        <div className="search-input-container">
          <input
            type="text"
            id="searchBar"
            placeholder="&#xf002;  Search company name ..."
            value={searchText}
            onChange={searchTextOnChange}
            onFocus={focusHandler}
            onBlur={blurHandler}
            style={{
              borderRadius: dropdownDisplay ? "0.6rem 0.6rem 0 0" : "0.6rem",
            }}
          />
          {searchText.length > 0 && (
            <i
              className="fa fa-times clear-search-bar"
              aria-hidden="true"
              onClick={clearSearchText}
            />
          )}
        </div>
      </Container>
      <a href="https://www.yourenext.ca/" className="website-button bottom-right-button">
        <FontAwesomeIcon icon={faChevronLeft} />  yourenext.ca
      </a>
    </div>
  );
}

export default SearchBar;
