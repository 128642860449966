import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "primereact/multiselect";
import {
  faPen,
  faGlasses,
  faHelmetSafety,
  faFlask,
  faBriefcase,
  faLaptopCode,
  faEllipsis,
  faPlane,
  faBolt,
  faGear,
  faUserGraduate,
  faKitMedical,
  faLandmarkDome,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./SearchFilters.css";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { Dropdown } from "primereact/dropdown";

function onWheel(apiObj, ev) {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }
  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

function SearchFilters({
  dropdownDisplay,
  onYearFilterChange,
  onDisciplineFilterChange,
  onIndustryFilterChange,
  selectedCompany
}) {
  const years = [
    { label: "All", value: "" },
    { label: "1", value: "1st Year" },
    { label: "2", value: "2nd Year" },
    { label: "3", value: "3rd Year" },
    { label: "4", value: "4th Year" },
    { label: "PEY", value: "PEY" },
    { label: "New", value: "New Grad" },
    { label: "M.", value: "Masters" },
    { label: "PhD", value: "PHD" },
  ];

  const disciplineData = [
    {
      text: "ECE",
      value: ["Electrical Engineering", "Computer Engineering"],
      colour: "blue",
    },
    {
      text: "INDY",
      value: "Industrial Engineering",
      colour: "red",
    },
    {
      text: "ENGSCI",
      value: "Engineering Science",
      colour: "green",
    },
    {
      text: "MECH",
      value: "Mechanical Engineering",
      colour: "yellow",
    },
    {
      text: "CS",
      value: "Computer Science",
      colour: "blue",
    },
    {
      text: "CIV",
      value: "Civil Engineering",
      colour: "red",
    },
    {
      text: "CHEM",
      value: "Chemical Engineering",
      colour: "green",
    },
    {
      text: "MIN",
      value: "Mineral Engineering",
      colour: "yellow",
    },
    {
      text: "MSE",
      value: "Material Engineering",
      colour: "blue",
    },
    {
      text: "AERO",
      value: "Aerospace Engineering",
      colour: "red",
    },
    {
      text: "COMMERCE",
      value: "Commerce & Business",
      colour: "green",
    },
    {
      text: "LIFESCI/BME",
      value: ["Life Sciences", "Biomedical Engineering"],
      colour: "yellow",
    },
    {
      text: "PHY/MATH",
      value: "Physical/Mathematical Sciences",
      colour: "blue",
    },
    {
      text: "OTHER",
      value: "Other",
      colour: "red",
    },
  ];

  const industryData = [
    {
      icon: faLaptopCode,
      text: "Software",
      value: "Software",
      colour: "blue",
    },
    {
      icon: faPlane,
      text: "Aerospace",
      value: "Aerospace",
      colour: "red",
    },
    {
      icon: faGlasses,
      text: "Consulting",
      value: "Consulting",
      colour: "blue",
    },
    {
      icon: faHelmetSafety,
      text: "CivMin",
      value: ["Civil/Mineral", "Civil", "Mineral"],
      colour: "blue",
    },
    {
      icon: faGear,
      text: "Mechanical",
      value: "Mechanical",
      colour: "red",
    },
    // {
    //   icon: faFlask,
    //   text: "Chemical",
    //   value: "Chemical",
    //   colour: "green",
    // },
    {
      icon: faFlask,
      text: "R&D",
      value: "Research and Development",
      colour: "green",
    },
    {
      icon: faBriefcase,
      text: "Finance",
      value: "Finance",
      colour: "green",
    },
    {
      icon: faPhone,
      text: "Consumer",
      value: "Consumer",
      colour: "green",
    },
    // {
    //   icon: faPen,
    //   text: "Design",
    //   value: "Design",
    //   colour: "green",
    // },
    {
      icon: faBolt,
      text: "Energy",
      value: "Energy",
      colour: "green",
    },
    // {
    //   icon: faKitMedical,
    //   text: "Healthcare",
    //   value: "Healthcare",
    //   colour: "red",
    // },
    {
      icon: faLandmarkDome,
      text: "Government",
      value: "Government",
      colour: "blue",
    },
    {
      icon: faUserGraduate,
      text: "Education",
      value: "Education",
      colour: "green",
    },
    {
      icon: faEllipsis,
      text: "OTHER",
      value: "Other",
      colour: "red",
    },
  ];

  const [yearFilter, setYearFilter] = useState(null);
  const [disciplineFilter, setDisciplineFilter] = useState([]);
  const [industryFilter, setIndustryFilter] = useState([]);

  const yearFilterOnChange = (event) => {
    const selectedYear = event.target.value;
    setYearFilter(selectedYear);
    onYearFilterChange(selectedYear);
  };

  const handleDisciplineFilterChange = (discipline) => {
    discipline = discipline.value;
    setDisciplineFilter(discipline || []);
    onDisciplineFilterChange((discipline || []).flat());
  };

  const handleIndustryFilterChange = (industry) => {
    let industryFilterCopy = [...industryFilter];
    if (Array.isArray(industry)) {
      const alreadyInFilter = industryFilterCopy.some((val) =>
        industry.includes(val)
      );
      if (alreadyInFilter) {
        industryFilterCopy = industryFilterCopy.filter(
          (val) => !industry.includes(val)
        );
      } else {
        industryFilterCopy.push(...industry);
      }
    } else {
      const alreadyInFilter = industryFilterCopy.includes(industry);
      if (alreadyInFilter) {
        industryFilterCopy = industryFilterCopy.filter(
          (val) => val !== industry
        );
      } else {
        industryFilterCopy.push(industry);
      }
    }
    setIndustryFilter(industryFilterCopy);
    onIndustryFilterChange([...industryFilterCopy]);
  };

  const buttonList = industryData.map((button, index) => (
    <button
      key={index}
      className={`button ${
        (
          Array.isArray(button.value)
            ? button.value.some((val) => industryFilter.includes(val))
            : industryFilter.includes(button.value)
        )
          ? "selected" + button.colour
          : ""
      }`}
      data-value={button.value}
      onClick={() => handleIndustryFilterChange(button.value)}
    >
      <div className="icontext-wrapper">
        <FontAwesomeIcon icon={button.icon} className="button-icon" />
        <span className="button-text">{button.text}</span>
      </div>
    </button>
  ));

  // clear the filters when a company booth is selected
  useEffect(() => {
    if (selectedCompany != null) {
      setYearFilter("");
      setDisciplineFilter([]);
      setIndustryFilter([]);

      onYearFilterChange("");
      onDisciplineFilterChange([]);
      onIndustryFilterChange([]);
    }
  }, [selectedCompany]);

  return (
    <div style={{ display: dropdownDisplay ? "none" : "block" }}>
      <Container>
        <div className="company-filters" id="companyFilters">
          <Dropdown
            className="year-dropdown"
            value={yearFilter}
            onChange={yearFilterOnChange}
            options={years}
            placeholder="Year"
            optionLabel="label"
            optionValue="value"
          />

          <MultiSelect
            className="discipline-dropdown"
            value={disciplineFilter}
            onChange={handleDisciplineFilterChange}
            options={disciplineData.map((button) => ({
              label: button.text,
              value: button.value,
            }))}
            maxSelectedLabels={1}
            showClear
            selectedItemsLabel="{0} disciplines selected"
            placeholder="Discipline"
            optionLabel="label"
            optionValue="value"
          />

          <div className="standard-partners">
            <div className="box-container">
              <ScrollMenu
                onWheel={onWheel}
                itemClassName="card-in-scroll"
                scrollContainerClassName="horizontal-slider"
              >
                {buttonList}
              </ScrollMenu>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SearchFilters;
