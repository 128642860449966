import React from "react";
import "./FloorDropdown.css";
import { Dropdown } from "primereact/dropdown";
import { floors } from "./FloorData";

function FloorDropdown({
  floor,
  onFloorChange,
}) {
  return (
    <div className="company-filters bottom-right-dropdown" id="companyFilters">
      <Dropdown
        className="floor-dropdown"
        value={floor}
        onChange={(event) => onFloorChange(event.value)}
        options={floors}
        optionLabel="label"
        optionValue="value"
      />
    </div>
  );
}

export default FloorDropdown;
