import React, { useEffect, useState } from "react";
import Booth from "./Booth";
import InteractiveIcon from "./interactive_icon/InteractiveIcon";
import SearchBar from "./search_bar/SearchBar";
import SearchAlgorithm from "./search_bar/SearchAlgorithm";
import SearchFilters from "./search_bar/SearchFilters";
import SearchDropdown from "./search_bar/SearchDropdown";
import FloorDropdown from "./Floors/FloorDropdown";
import { floors } from "./Floors/FloorData";
import CompanyInfoPanel from "./CompanyInfoPanel/CompanyInfoPanel";
import IconInfoPanel from "./icon_info_panel/IconInfoPanel";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import Tutorial from "./Map_Tutorial/Tutorial";
import SvgMap from "./SvgMap";
import "./Map.css";

import { interactiveIconInfo, exitIconInfo } from "./interactive_icon/InteractiveIconInfo";

function MapParent() {
  const [floor, setFloor] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [searchedBooths, setSearchedBooths] = useState([]);
  const [dropdownBooths, setDropdownBooths] = useState([]);
  const [dropdownDisplay, setDropdownDisplay] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [yearFilter, setYearFilter] = useState("");
  const [disciplineFilter, setDisciplineFilter] = useState([]);
  const [industryFilter, setIndustryFilter] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [infoPanelOpen, setInfoPanelOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    fetch("/api/cf_map_companies")
      .then((response) => response.json())
      .then((json_data) => {
        setCompanies(
          json_data.companies.map((company) => {
            const {
              x_coordinate,
              y_coordinate,
              floor,
              rotation,
              width,
              height,
              boothid,
              ...data
            } = company;

            return {
              ...data,
              visible: false,
              booth: {
                id: boothid,
                x: x_coordinate,
                y: y_coordinate,
                floor: floor ?? 1,
                rotation,
                width,
                height,
              },
            };
          })
        );
      });
  }, []);

  useEffect(() => {
    const updated_companies = companies.map((company) => {
      const selected_by_year_filter = company.target_years.includes(yearFilter);
      const selected_by_discipline_filter = disciplineFilter.some(
        (discipline) => company.target_programs.includes(discipline)
      );
      const selected_by_industry_filter = industryFilter.some((industry) =>
        company.industry.includes(industry)
      );
      const selected_by_search_bar = searchedBooths.some(
        (booth) => booth.booth.id === company.booth.id
      );

      const dontCareAboutDiscipline = disciplineFilter.length === 0;
      const dontCareAboutYear = yearFilter === "";
      const dontCareAboutSearchbar = searchText === "";
      const dontCareAboutIndustry = industryFilter.length === 0;

      let isVisible;
      if (
        dontCareAboutDiscipline &&
        dontCareAboutYear &&
        dontCareAboutSearchbar &&
        dontCareAboutIndustry
      ) {
        isVisible = false;
      } else {
        const disciplineHasNoComplaintsToDisplay =
          selected_by_discipline_filter || dontCareAboutDiscipline;
        const yearHasNoComplaintsToDisplay =
          selected_by_year_filter || dontCareAboutYear;
        const searchbarHasNoComplaintsToDisplay =
          selected_by_search_bar || dontCareAboutSearchbar;
        const industryHasNoComplaintsToDisplay =
          selected_by_industry_filter || dontCareAboutIndustry;

        isVisible =
          disciplineHasNoComplaintsToDisplay &&
          yearHasNoComplaintsToDisplay &&
          searchbarHasNoComplaintsToDisplay &&
          industryHasNoComplaintsToDisplay;
      }

      return {
        ...company,
        visible: isVisible,
      };
    });
    setCompanies(updated_companies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearFilter, disciplineFilter, searchedBooths, industryFilter]);


  const handleZoomChange = (zoomEvent) => {
    setZoomLevel(zoomEvent.a); // a is the zoom level
  };

  const handleFloorChange = (selectedFloor) => {
    setFloor(selectedFloor);
  };

  const handleYearFilterChange = (selectedYear) => {
    if (selectedYear === "None") {
      setYearFilter("");
    } else {
      setYearFilter(selectedYear);
    }
  };

  const handleDisciplineFilterChange = (selectedDisciplines) => {
    const tempFilter = [];
    for (let i = 0; i < selectedDisciplines.length; i++) {
      if (selectedDisciplines[i] === "Other") {
        tempFilter.push("Social Sciences", "Architecture", "Other");
      } else {
        tempFilter.push(selectedDisciplines[i]);
      }
    }
    setDisciplineFilter(tempFilter);
  };

  const handleIndustryFilterChange = (selectedIndustries) => {
    const add = (temp, filter) => {
      for (let j = 0; j < filter.length; j++) {
        temp.push(filter[j]);
      }
    };
    const tempFilter = [];
    const otherFilters = ["Consumer", "Other"];
    for (let i = 0; i < selectedIndustries.length; i++) {
      if (selectedIndustries[i] === "Other") {
        add(tempFilter, otherFilters);
      } else {
        tempFilter.push(selectedIndustries[i]);
      }
    }
    setIndustryFilter(tempFilter);
  };

  const handleDropdownDisplay = (query) => {
    if (query === "") {
      setDropdownDisplay(false);
    } else {
      setDropdownDisplay(true);
    }
  };

  const handleSearchChange = (query) => {
    const selected = SearchAlgorithm(companies, query);
    setSearchedBooths(selected);
    setDropdownBooths(selected.filter((company) => company.name !== ""));
    handleDropdownDisplay(query);
    if (selected.length === 0) {
      setSelectedCompany(null);
    }
  };

  const handleBoothClick = (company) => {
    if (company.name) {  // Do nothing if company name does not exist (e.g. company hasn't onboarded)
      handleDropdownClick(company);
    }
  };

  const handleDropdownClick = (company) => {
    setSearchText(company.name);
    setFloor(company.booth.floor);
    setSearchedBooths([company]);
    setSelectedCompany(company);
    setInfoPanelOpen(true);
  };

  const handleInteractiveIconClick = (info) => {
    setSearchText("");
    setSelectedCompany(null);
    setSearchedBooths([]);
    setSelectedIcon(info);
    setInfoPanelOpen(true);
  }

  const handleClearText = () => {
    setInfoPanelOpen(false);
    setSelectedCompany(null);
  };

  const handleShowConfirmationModal = (info) => {
    setInfoPanelOpen(false);
    setSelectedIcon(info);
    setShowConfirmationModal(true);
  }
  const handleCloseConfirmationModal = () => {
    setSelectedIcon(null);
    setShowConfirmationModal(false);
  }

  const handleExitMap = () => {
    window.location.replace("https://yourenext.ca");
  }

  return (
    <div>
      <Tutorial />
      <div className="search-filters">
        <SearchBar
          searchText={searchText}
          dropdownDisplay={dropdownDisplay}
          setSearchText={setSearchText}
          setDropdown={setDropdownDisplay}
          onSearchChange={handleSearchChange}
          onClearText={handleClearText}
        />
        <SearchDropdown
          companies={dropdownBooths}
          display={dropdownDisplay}
          onDropdownClick={handleDropdownClick}
        />
        <SearchFilters
          dropdownDisplay={dropdownDisplay}
          onYearFilterChange={handleYearFilterChange}
          onDisciplineFilterChange={handleDisciplineFilterChange}
          onIndustryFilterChange={handleIndustryFilterChange}
          selectedCompany={selectedCompany}
        />
        {floors.length > 1 &&
          <FloorDropdown
            floor={floor}
            onFloorChange={handleFloorChange}
          />
        }
      </div>
      <SvgMap onZoomChange={handleZoomChange} floor={floor}>
        {companies
          .filter((company) => company.booth.floor === floor)
          .map((company) => (
            <Booth
              key={company.booth.id}
              company={company}
              onClick={handleBoothClick}
              zoomLevel={zoomLevel}
              setZoomLevel={setZoomLevel}
            />
        ))} 
        {interactiveIconInfo
          .filter((info) => info.floor === floor)
          .map((info) => (
            <InteractiveIcon
              key={info.title}
              info={info}
              highlight={selectedIcon && (info.title === selectedIcon.title)}
              onClick={handleInteractiveIconClick}
            />
        ))}
        {exitIconInfo.floor === floor &&
          <InteractiveIcon
            key={exitIconInfo.title}
            info={exitIconInfo}
            highlight={selectedIcon && (exitIconInfo.title === selectedIcon.title)}
            onClick={handleShowConfirmationModal}
          />
        }
      </SvgMap>
      <ConfirmationModal
        info={selectedIcon}
        show={showConfirmationModal}
        handleClose={handleCloseConfirmationModal} 
        handleConfirm={handleExitMap}
      />
      {selectedCompany ? 
          <CompanyInfoPanel
            company={selectedCompany}
            open={infoPanelOpen}
            onClose={() => setInfoPanelOpen(false)}
          /> :
          <IconInfoPanel 
            iconInfo={selectedIcon}
            open={infoPanelOpen}
            onClose={() => {
              setSelectedIcon(null);
              setInfoPanelOpen(false);}
            }
          />
      }
    </div>
  );
}

export default MapParent;
