import CheckinDesk from "../../assets/icons/checkin_desk.svg";
import HelpDesk from "../../assets/icons/help_desk.svg";
import PhotoBooth from "../../assets/icons/photo_booth.svg";
import QuietRoom from "../../assets/icons/quiet_room.svg";
import Workshops from "../../assets/icons/workshops.svg";
import CoatCheck from "../../assets/icons/coat_check.svg";
import Exit from "../../assets/icons/exit.svg";
import Stairs from "../../assets/icons/stairs.svg";
import ExitStairs from "../../assets/icons/exit_stairs.svg";



export const interactiveIconInfo = [
    {
        title: "Check-In Desk",
        icon: CheckinDesk,
        description: "As your first step of the day, make sure to check-in at this desk before entering \
        the career fair! This helps us keep track of attendance during the event.",
        x: 2206,
        y: 704,
        floor: 1,
        width: 90,
        height: 90,
        delay: 100,
    },
    {
        title: "Check-In Desk",
        icon: CheckinDesk,
        description: "As your first step of the day, make sure to check-in at this desk before entering \
        the career fair! This helps us keep track of attendance during the event.",
        x: 900,
        y: 1560,
        floor: 1,
        width: 90,
        height: 90,
        delay: 100,
    },
    {
        title: "Help Desk",
        icon: HelpDesk,
        description: "Come by our help desk to ask any questions or receive help on how to navigate \
        the career fair!",
        x: 2206,
        y: 934,
        floor: 1,
        width: 90,
        height: 90,
        delay: 100,
    },
    {
        title: "Photo Booths",
        icon: PhotoBooth,
        description: "Need a professional head shot photo  to really take your LinkedIn's \
        professionalism to the next level? Or maybe you'd like a nice photo for the career fair \
        memories! Come check out our photo booth to get your photo taken!",
        x: 2206,
        y: 303,
        floor: 1,
        width: 90,
        height: 90,
        delay: 300,
    },
    {
        title: "Workshop",
        icon: Workshops,
        description: "If you have been accepted into a workshop held by one of our Gold sponsors, \
        come join them in this space and enjoy their specially crafted workshop content!",
        x: 194,
        y: 526,
        floor: 2,
        width: 120,
        height: 120,
        delay: 500,
    },
    // {
    //     title: "Quiet Room",
    //     icon: QuietRoom,
    //     description: "Need a moment away from the busy crowd at the career fair? Come by and take a \
    //     relaxing break in our quiet room before going back out there to network with more companies!",
    //     x: 240,
    //     y: 1117,
    //     floor: 1,
    //     width: 50,
    //     height: 50,
    //     delay: 400,
    // },
    {
        title: "Coat Check",
        icon: CoatCheck,
        description: "Come drop off your coats at our coat check so that you can walk around \
        freely to meet and network with everyone!",
        x: 1289,
        y: 1082,
        floor: 1,
        width: 120,
        height: 120,
        delay: 0,
    },
    {
        title: "Stairs - First Floor",
        icon: Stairs,
        description: "Used to access different levels of the venue!",
        x: 1062,
        y: 1224,
        floor: 1,
        width: 120,
        height: 120,
        delay: 0,
    },
    {
        title: "Stairs - Second Floor",
        icon: Stairs,
        description: "Used to access different levels of the venue!",
        x: 2165,
        y: 559,
        floor: 2,
        width: 120,
        height: 120,
        delay: 0,
    },
    {
        title: "Exit Stairs - Second Floor",
        icon: ExitStairs,
        description: "You can use these stairs to enter/exit the career fair venue, connecting to other parts of Hart House!",
        x: 1041,
        y: 572,
        floor: 2,
        width: 120,
        height: 120,
        delay: 0,
    }
];

export const exitIconInfo = {
    // title: "Exit",
    // icon: Exit,
    // description: "Are you sure you want to exit the map and return to our homepage?",
    // x: 484,
    // y: 1108,
    // floor: 1,
    // width: 63,
    // height: 86,
    // delay: 200,
};