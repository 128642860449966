import React, { useEffect, useRef, useState } from "react";
import { ReactSVGPanZoom, INITIAL_VALUE, TOOL_AUTO } from "react-svg-pan-zoom";
import { useWindowSize } from "@react-hook/window-size";
import { floors } from "./Floors/FloorData";

//TODO: figure out how to get bounding box of child svgs

function SvgMap({ onZoomChange, children, floor }) {
  const groupRef = useRef(null);
  const Viewer = useRef(null);
  const [tool, setTool] = useState(TOOL_AUTO);
  const [value, setValue] = useState(INITIAL_VALUE);
  const [width, height] = useWindowSize({
    initialWidth: 400,
    initialHeight: 400,
  });

  const MAP_WIDTH = floors[floor - 1].width;
  const MAP_HEIGHT = floors[floor - 1].height;

  // Centers the map on load
  useEffect(() => {
    Viewer.current.setPointOnViewerCenter(MAP_WIDTH / 2, MAP_HEIGHT / 2, 0.3);
  }, []);

  return (
    <>
      <ReactSVGPanZoom
        ref={Viewer}
        width={width}
        height={height}
        scaleFactorMax={1.7}
        scaleFactorMin={0.3}
        background="rgb(236,244,251)"
        tool={tool}
        onChangeTool={(tool) => setTool(tool)}
        value={value}
        onChangeValue={(newValue) => {
          if (value.a !== newValue.a) {
            // Check if the scale factor has changed
            onZoomChange(newValue);
          }
          setValue(newValue);
        }}
        detectAutoPan={false}
        toolbarProps={{ position: "none" }}
        miniatureProps={{ position: "none" }}
        onZoom={(e) => onZoomChange(e)}
        onPan={(e) => onZoomChange(e)}
      >
        <svg width={MAP_WIDTH} height={MAP_HEIGHT}>
          <g ref={groupRef}>
            <image
              href={floors[floor - 1].svg /* Floor numbers are 1-indexed*/}
              width={MAP_WIDTH}
              height={MAP_HEIGHT}
            />
            {children}
          </g>
        </svg>
      </ReactSVGPanZoom>
    </>
  );
}

export default SvgMap;
